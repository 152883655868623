import React, { Component } from "react";
import background from "./waves.mp4";
import "./App.css";

const EmailMessages = Object.freeze({
    validEmail: "This is a valid email address",
    invalidEmail: "Sorry! That is not a valid email.",
    emailAddressSentToServer: "Thanks! We will be in touch shortly!",
    emailAddressFailedToSendToServer: "Sorry! We cannot connect to the server at this time."
});

const ValidationType = Object.freeze({
    email: "Email",
    server: "Server"
});

class App extends Component {
    state = {
        email: "",
        emailSuccessMessage: EmailMessages.validEmail,
        emailFailMessage: EmailMessages.invalidEmail,
        shouldShowEmailValidation: false,
        emailValidationClass: "",
        sentEmailToServer: false
    };

    handleEmailChange = (event) => {
        this.setState({email: event.target.value});
        this.handleValidation(this.state.shouldShowEmailValidation, ValidationType.email, event.target.value);
    };

    /**
     *
     * @param shouldShowValidation
     * @param validationType
     * @param toValidateOn This is either an email address or a boolean
     */
    handleValidation = (shouldShowValidation, validationType, toValidateOn) => {
        if (shouldShowValidation) {
            if (validationType === ValidationType.email) {
                this.setState({
                    emailSuccessMessage: EmailMessages.validEmail,
                    emailFailMessage: EmailMessages.invalidEmail,
                    emailValidationClass: this.determineClassFromEmailAddress(toValidateOn)
                });
            } else if (validationType === ValidationType.server) {
                this.setState({
                    emailSuccessMessage: EmailMessages.emailAddressSentToServer,
                    emailFailMessage: EmailMessages.emailAddressFailedToSendToServer,
                    emailValidationClass: this.validateBooleanToClassName(toValidateOn)
                });
            }
        } else {
            this.setState({
                emailValidationClass: "" 
            });
        }

    };

    isValidEmailAddress(emailAddress) {
        if (typeof emailAddress !== "string") return false;
        let lastAtSign = emailAddress.lastIndexOf("@");
        if (lastAtSign === -1) return false;
        let existsADotAfterAt = emailAddress.indexOf(".", lastAtSign);
        if (lastAtSign + 2 > existsADotAfterAt) return false;
        if (existsADotAfterAt === -1) return false;
        let existsAtLeastTwoLettersAfterDot = existsADotAfterAt + 2 < emailAddress.length;
        return existsAtLeastTwoLettersAfterDot;
    }

    validateBooleanToClassName(booleanValue) {
        if (booleanValue) {
            return "is-valid";
        } else {
            return "is-invalid";
        }
    }

    determineClassFromEmailAddress = (emailAddress) => {
        return this.validateBooleanToClassName(this.isValidEmailAddress(emailAddress));
    };

    handleSubmitEmail = () => {
        this.setState({shouldShowEmailValidation: true});
        let isValidEmail = this.isValidEmailAddress(this.state.email);
        if (isValidEmail) {
            this.postEmailAddress(this.state.email, (itPosted) => {
                this.handleValidation(true, ValidationType.server, itPosted);
            });
        } else {
            this.handleValidation(true, ValidationType.email, this.state.email);
        }
    };

    handleKeyPress = (event) => {
        if (event.key === "Enter") {
            this.handleSubmitEmail();
        }
    };

    render() {
        return (
            <div className="App">
                <video muted autoPlay loop className="background">
                    <source src={background} type="video/mp4"/>
                </video>
                <div className="floater text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                            </div>
                            <div className="col-sm-6">
                                <h1 className="logo">Compondria</h1>
                                <h2>Software Development Company</h2>
                                <div className="mb-2">
                                    <div className="mb-2">
                                        <input type="email" className={"form-control " + this.state.emailValidationClass} placeholder="email@example.com"  value={this.state.email} onChange={this.handleEmailChange} onKeyPress={this.handleKeyPress}/>
                                        <div className="valid-feedback feedback-banner">
                                            {this.state.emailSuccessMessage}
                                        </div>
                                        <div className="invalid-feedback feedback-banner">
                                            {this.state.emailFailMessage} 
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-success mb-2" onClick={this.handleSubmitEmail}>Get In Touch</button>
                                </div>
                            </div>
                            <div className="col">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    postEmailAddress = (email, callbackFunction) => {
        window.$.ajax({
            type: "POST",
            url: "/email",
            data: {"email": email},
            error: () => {callbackFunction(false);},
            success: () => {callbackFunction(true);}
        });
    }
}

export default App;
